import React, { useState ,useEffect} from "react";
import Logo from "../../assets/logo.png";
import "../All.css";
import { Link } from "react-scroll";
const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const displayStyle = {fontSize:"30px",color:"white",zIndex:999,position:"relative",left:"10px",display:mobile? 'block' : 'none'};
  const [menuOpened, setMenuOpened] = useState(false);
  return (
    <div className="header" id="header">
      <img src={Logo} alt="" className="logo" />
      {(menuOpened===false && mobile===true)? (
            <ion-icon name="menu-outline" style={displayStyle}
            onClick={() => setMenuOpened(true)}
            ></ion-icon>
      ) : (
        <>
        <ul className="header-menu">
        <ion-icon name="close-outline" className="my" style={{fontSize:"30px",color:"white",position:"relative",left:"50px",top:"10px",borderRadius:"50%",background:"black",display:mobile? 'block' : 'none' }}
        onClick={() => setMenuOpened(false)}
        ></ion-icon>
          <li>
            <Link
              onClick={() => setMenuOpened(false)}
              activeClass="active"
              to="header"
              spy={true}
              smooth={true}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setMenuOpened(false)}
              to="programs"
              spy={true}
              smooth={true}
            >
              Programs
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setMenuOpened(false)}
              to="reasons"
              spy={true}
              smooth={true}
            >
              Why us
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setMenuOpened(false)}
              to="plans"
              spy={true}
              smooth={true}
            >
              Plans
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setMenuOpened(false)}
              to="testimonials"
              spy={true}
              smooth={true}
            >
              Testimonials
            </Link>
          </li>
        </ul>
               </>
       )}

    </div>
  );
};

export default Header;
